import * as React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout pageTitle="404: Not found">
      <p>You just hit a page that doesn&#39;t exist...try again.</p>
    </Layout>
  )
}

export default NotFoundPage
